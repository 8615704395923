<template>
  <div class = "banner">
    <div class = "Limit">
      <el-carousel :interval = "6000" arrow = "hover" :height = "BannerHeight+ 'px' " :autoplay = "true"
                   ref = "carousel" trigger = "click" indicator-position = "none">
        <el-carousel-item v-for = "item in imgList" :key = "item.id">
          <div class = "img_con">
            <img class = "element-img" alt = "" :src = "item.imgUrl">
            <div class = "img_cover"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class = "overlay">
      <div class = "slogan sofiaBold">
        <div class = "slogan-subtitle">技术立企、创新兴企、人才强企</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['BannerHeight'],
  data () {
    return {
      imgList: [
        {
          id: 0,
          imgUrl: require('../../assets/img/banner1.png')
        },
        {
          id: 1,
          imgUrl: require('../../assets/img/banner2.png')
        },
        {
          id: 2,
          imgUrl: require('../../assets/img/banner3.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.banner {
  margin-top: 130px;
  position: relative;
}
.img_cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.img_con {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(51, 112, 255, .2);
    height: 100%;
    z-index: 666;
  }
}
.element-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 30px;
  background-color: rgba(255, 255, 255, .1);
}
.el-carousel__arrow:hover {
  background-color: rgba(255, 255, 255, .3);
}
.overlay {
  position: absolute;
  width: 100%;
  height: 90px;
  bottom: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  cursor: default;
  z-index: 9999;
  //background: rgba(51,112,255,.2);
  .slogan {
    font-size: 80px;
    line-height: 80px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 800;

    .slogan-subtitle {
      letter-spacing: 18px;
      text-indent: 18px;
      font-size: 20px;
      line-height: 20px;
      opacity: .8;
      position: relative;
    }
  }
}

.slogan .slogan-subtitle:after, .slogan .slogan-subtitle:before {
  content: "";
  display: block;
  width: 74px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #fff 47%, hsla(0, 0%, 100%, 0));

}
.slogan .slogan-subtitle:before {
  left: -60px;
}

.slogan .slogan-subtitle:after {
  right: -60px;
}
</style>
