<template>
  <div class = "wrap-block">
    <div class = "content-block service-block">
      <div class = "service-block">
        <div class = "block-line"></div>
      </div>
    </div>
    <div class = "content-block service-idea">
      <div class = "service-idea">
        <el-row>
          <el-col :span="10" class = "idea-left">
            <el-carousel width="100%" height="300px" :interval="6000" indicator-position = "none" arrow="always">
              <el-carousel-item v-for="item in listData[listFlag]" :key="item.id">
                <img class="left-img" :src="'https://app.dtspd.com/miniodata/dalianportal/' + item.img" v-if="item.img"/>
                <div class="no-data" v-if="!item.img">暂无图片</div>
                <div class="left-title">{{ item.caption }}</div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="14" class = "idea-right">
            <div class = "idea-title">
              <span :class="listFlag === '公司新闻' ? 'active' : ''" @click="changeList('公司新闻')">公司新闻</span>
              <span> / </span>
              <span :class="listFlag === '行业新闻' ? 'active' : ''" @click="changeList('行业新闻')">行业新闻</span>
            </div>
            <ul class = "idea-list">
              <li class="list-item" v-for="item in listData[listFlag]" :key="item.id" @click="opennewsDetail(item)">
                <span class="title"><i class="el-icon-share"></i> {{ item.caption }}</span>
                <span class="time">{{ changeTime(item.time) }}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class = "content-block service-block" style="margin-top: 48px;">
      <div class = "service-block">
        <div class = "block-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'IndexService',
  data () {
    return {
      listFlag: '公司新闻',
      listData: {
        公司新闻: [],
        行业新闻: [],
        党建专栏: []
      }
    }
  },
  watch: {
  },
  mounted () {
    this.initIntro()
  },
  methods: {
    changeList (val) {
      this.listFlag = val
    },
    initIntro () {
      // 获取列表
      var _this = this
      _this.list = []
      axios.get('https://app.dtspd.com/miniodata/dalianportal/dalian_gsxw.json')
        .then(response => {
          var data1 = response.data
          const sortedData = data1.sort((a, b) => b.id - a.id)
          const lastFiveItems = sortedData.slice(0, 5)
          _this.listData['公司新闻'] = lastFiveItems
        })
        .catch(error => {
          console.log(error)
        })
      axios.get('https://app.dtspd.com/miniodata/dalianportal/dalian_hyxw.json')
        .then(response => {
          var data2 = response.data
          const sortedData = data2.sort((a, b) => b.id - a.id)
          const lastFiveItems = sortedData.slice(0, 5)
          _this.listData['行业新闻'] = lastFiveItems
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeTime (val) {
      const dateParts = val.split('/')
      const newFormatDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      return newFormatDate
    },
    opennewsDetail (item) {
      if (item.url) {
        window.open(item.url, '_blank')
      } else {
        this.$message('请等待数据更新，谢谢！')
      }
    }
  }
}
</script>

<style lang = "scss">
.wrap-block {
  position: relative;
  padding: 54px 10% 0;

  .content-block {
    position: relative;
    //width: 100%;
    overflow: hidden;
  }
}

.service-block {
  max-width: 1440px;
  height: 54px;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;

  .block-line {
    margin: 0 auto 12px;
    width: 24px;
    height: 4px;
    background-color: #3370ff;
    border-radius: 100px;
  }

  .service-info-title {
    font-weight: 700;
    font-size: 32px;
    position: relative;
    bottom: 2px;
    //padding-top: 60px;
  }

  .service-info-subtitle {
    font-size: 16px;
    line-height: 1.5;
    color: #646a73;
    margin-top: 30px;
  }

  .service-list {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    //padding: 0 200px;
    margin: 70px auto 0 auto;
  }

  .service-title-shadow {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
  }

  .service-title-shadow:hover {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    transform: scale(1.05) translateY(-1px);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .15);
  }

  .service-name {
    margin-top: 30px;
    font-weight: 600;
    font-size: 20px;
  }
}

.content-block.service-idea {
  background-color: #F0F0F0;
  width: 100%;

  .service-idea {
    //height: 700px;
    overflow: hidden;
  }

  .idea-left {
    position: relative;
    height: 300px;
    display: inline-block;
    .left-img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
    .left-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, .75);
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
    .no-data {
      width: calc(100% - 10px);
      height: 100%;
      text-align: center;
      line-height: 300px;
      color: #909399;
      font-weight: bold;
    }
  }

  .idea-right {
    height: 300px;
    padding-top: 20px;
    padding-left: 40px;
    display: inline-block;

    .block-line {
      margin: 0 0 12px 0;
      width: 24px;
      height: 4px;
      background-color: #3370ff;
      border-radius: 100px;
    }

    .idea-title {
      font-size: 24px;
      position: relative;
      bottom: 2px;
      color: #909399;
      span {
        display: line-block;
        cursor: pointer;
      }
      .active {
        font-weight: 700;
        color: #0b1660;
      }
    }

    .idea-subtitle {
      font-size: 16px;
      line-height: 1.5;
      color: #646a73;
      margin-top: 10px;
    }

    .idea-list {
      margin-top: 15px;
      width: calc(100% - 50px);
      .list-item {
        cursor: pointer;
        display: flex;
        padding: 10px 0;
        position: relative;
        color: #606266;
        font-size: 13px;
        transition: all .3s;
        border-bottom: 1px dashed #909399;
        .time {
          display: inline-block;
          width: 85px;
          text-align: right;
        }
        .title {
          display: inline-block;
          width: calc(100% - 85px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .list-item:hover {
        color: #0b1660;
        opacity: 0.8;
      }
    }

    .el-button {
      margin-top: 40px;
    }
  }

}

.content-block.service-case {
  background-color: #FFFFFF;
  width: 100%;

  .service-case {
    max-width: 1440px;
    //height: 700px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    padding-top: 50px;

    .block-line {
      margin: 0 auto 12px;
      width: 24px;
      height: 4px;
      background-color: #3370ff;
      border-radius: 100px;
    }

    .case-title {
      font-weight: 700;
      font-size: 32px;
      position: relative;
      bottom: 2px;
      //padding-top: 60px;
    }

    .case-subtitle {
      font-size: 16px;
      line-height: 1.5;
      color: #646a73;
      margin-top: 10px;
    }

    .case-list {
      display: flex;
      justify-content: space-evenly;
    }

    .el-card {
      width: 247px;
      height: 217px;
      margin-top: 40px;
    }

    .el-progress {
      margin-top: 10px;
    }

    .el-progress-bar__outer {
      height: 2px !important;
    }

    .case-desc {
      margin-top: 30px;
      margin-bottom: 60px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
</style>
