<template>
  <div>
    <PCNavbar selectIndex="1"/>
    <MobileNavBar selectIndex="1"/>
    <CarouselHome :BannerHeight = "BannerHeight"/>
    <NewsInfomation/>
    <CallMe/>
    <MobileCallme/>
  </div>
</template>

<script>
import PCNavbar from '@/components/navbar/index'
import MobileNavBar from '@/components/navbar/MobileNavBar.vue'
import CarouselHome from '@/components/carousel_home/index'
import NewsInfomation from '@/components/news_infomation/index'
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  components: {
    PCNavbar,
    MobileNavBar,
    CarouselHome,
    NewsInfomation,
    CallMe,
    MobileCallme
  },
  data () {
    return {
      BannerHeight: ''
    }
  },
  mounted () {
    // 页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener('resize', this.setBannerHeight)
    this.setBannerHeight()
  },
  methods: {
    // 设置 Banner 高度
    setBannerHeight () {
      this.BannerHeight = window.innerHeight/2
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom_circle {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  background-image: url(../assets/img/smalldot.png),url(../assets/img/smalldot1.png);
  background-position: 0 0,110% 150%;
  background-repeat: no-repeat;
  background-size: 400px;
  h1 {
    font-size: 40px;
    padding-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 1.15rem;
      width: 60%;
      margin: 0 auto;
    }
  }
  .circle_img {
    width: 100%;
    img {
      width: 70%;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 20PX 0;
      }
    }
  }
}
</style>